import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import AcademicCard from "../components/Card/AcademicCard"
import OurMockTest from '../components/OurMockTest.js'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <main>
      <section className="homeBannerSection">
            <div className="homeBannerContent">
            <div className="container">
                <h1><span className="colorBlue">Take a Study</span> <span className="colorGreen">Abroad</span> <span className="d-block">Mock Test – FREE!</span></h1>  
                <ul>
                  <li>Developed By Experts</li>
                  <li>Simulated Test Engine</li>
                  <li>Instant Results</li>
                </ul>
            </div>
         </div>
      </section>
      <section className="homeAcademicSection">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <AcademicCard title="IELTS Academic" content="ELTS (The International English language Testing System)'s Academic test is a prerequisite for non-native students who wish to pursue higher studies in countries where the medium of instruction is English." />
               </div> 
               <div className="col-lg-4 col-sm-6">
                  <AcademicCard title="IELTS General" content="The IELTS General test is a pre-requisite for people who are going to English speaking countries for secondary education, work experience or training programs. The test focuses on basic survival skills in social contexts. " />
               </div> 
               <div className="col-lg-4 col-sm-6">
                  <AcademicCard title="Duolingo English Test" content="The Duolingo English Test is a fast-growing English profciency certifcation accepted by over 2,000 institutions. You can take it anywhere, anytime and is highly affordable. Test results are available as soon as two days." />
               </div>
               <div className="col-lg-4 col-sm-6">
                <AcademicCard title="SAT" content="SAT (Scholastic Assessment Test) is an international standardized test conducted for getting admission in colleges in the United States. The purpose of the SAT is to measure a high school student’s readiness for college." />
              </div> 
              <div className="col-lg-4 col-sm-6">
                <AcademicCard title="GRE" content="The Graduate Record Examinations (GRE) is a standardized test that is an admissions requirement for many graduate schools in the United States and Canada." />
              </div> 
              <div className="col-lg-4 col-sm-6">
                <AcademicCard title="PTE Academic" content="PTE (Pearson Test of English) is an English-language test trusted by universities, colleges and governments abroad. An alternative to IELTS Academic, it is an accepted entry requirement from people wishing to study or immigrate abroad." />
              </div>
            </div>
            <div className="row">
            <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-4">
              <AcademicCard title="TOEFL" content="TOEFL (Test of English as a Foreign Language) evaluates the English language profciency of non-native speakers wishing to enrol in an educational institutions in English-speaking countries. TOEFL is an alternative to IELTS and PTE." />
            </div> 
            </div>
         </div>
      </section>
      <OurMockTest />
    </main>
  </Layout>
)

export default IndexPage
