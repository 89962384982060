import React from 'react'
import {Link} from 'gatsby'

const OurMockTest = () => {
   return( 
   <React.Fragment>
      <section className="oruMockTestSect">
          <div className="container">
              <h3>Our Mock Tests <span>are Created for Those Who Aspire to</span></h3>
              <div className="mockListOuter">
                <div className="row">
                    <div className="col-md-4">
                        <ul>
                            <li><Link to="/">Study in USA</Link></li>
                            <li><Link to="/">Study in Canada</Link></li>
                            <li><Link to="/">Study in UK</Link></li>
                            <li><Link to="/">Study in Australia</Link></li>
                            <li><Link to="/">Study in Ireland</Link></li>
                            <li><Link to="/">Study in New Zealand</Link></li>
                            <li><Link to="/">Study in Germany</Link></li> 
                        </ul>
                    </div>
                    <div className="col-md-4 borderCenter">
                        <ul>
                            <li><Link to="/">Work in USA </Link></li>
                            <li><Link to="/">Work in Canada </Link></li>
                            <li><Link to="/">Work in UK </Link></li>
                            <li><Link to="/">Work in Australia</Link></li>
                            <li><Link to="/">Work in Ireland</Link></li>
                            <li><Link to="/">Work in New Zealand</Link></li>
                            <li><Link to="/">Work in Germany</Link></li> 
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul>
                            <li><Link to="/">Live in USA</Link></li>
                            <li><Link to="/">Live in Canada</Link></li>
                            <li><Link to="/">Live in UK</Link></li>
                            <li><Link to="/">Live in Australia</Link></li>
                            <li><Link to="/">Live in Ireland</Link></li>
                            <li><Link to="/">Live in New Zealand</Link></li>
                            <li><Link to="/">Live in Germany</Link></li> 
                        </ul>
                    </div>
                </div>     
              </div>   
          </div>
      </section>  
   </React.Fragment>
  ) 
}

export default OurMockTest