import React from 'react'
import {Link} from 'gatsby'

const AcademicCard = (props) => {

   const {title, content, link} = props

   return( 
   <React.Fragment>
        <div className="aceBox">
            <h4>{title}</h4> 
            <p>{content}</p>
            <Link className="btn btn-mock" to={link ? link : '/ielts-academic/listening/'}>TRY MOCK TEST</Link>
        </div>
   </React.Fragment>
  ) 
}

export default AcademicCard